import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/footer/footer'
import Head from '../components/head'
import { Helmet } from "react-helmet"

import blogStyles from '../styles/blog.module.scss'

const BlogPage = () => {
     const data = useStaticQuery(graphql`
        query {
            allContentfulBlogPost ( sort: { fields: publishedDate, order: DESC } ) {
                edges {
                    node {
                        title
                        slug
                        publishedDate(formatString:"MMMM Do, YYYY")
                    }
                }
            }
        }
     `)

    return (
        <section className={blogStyles.blog}>
            <Helmet>
                <meta name="description" content="Mo is a front-end developer from Manchester, England who has an eye for design and user experience. 
                My goal is to deliver quality creative solutions across digital platforms and beyond."/>
            </Helmet>
            <Head title="Blog"/>
            <div className={blogStyles.blog__container}>
            <h1 className={blogStyles.blog__logo}><Link className={blogStyles.blog__logoLink} to="/">Mo.</Link></h1>
            <div className={blogStyles.blog__home}><Link className={blogStyles.blog__link} to="/"><FontAwesomeIcon icon={faLongArrowAltLeft} /> Back</Link></div>
            <h2 className={blogStyles.blog__title}>Posts:</h2> 
            <ol className={blogStyles.blog__list}>
                {data.allContentfulBlogPost.edges.map((edge) => {
                    return (
                        <li className={blogStyles.blog__item}>
                            <Link className={blogStyles.blog__itemLink} to={`/blog/${edge.node.slug}`}>
                                <h2 className={blogStyles.blog__itemTitle}>{edge.node.title}</h2>
                                <p className={blogStyles.blog__itemDate}>{edge.node.publishedDate}</p>
                            </Link>
                            <hr></hr>
                        </li>
                    )
                })}
            </ol>
            </div>
            <Footer />
        </section>
    )
}

export default BlogPage
